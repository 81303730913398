<!-- 解决方案菜单页面组件 -->
<template>
  <div class="mobile-container" v-title="'解决方案'">
    <div class="header">
      <mobile-header></mobile-header>
    </div>
    <div class="body">
      <div class="body-banner">
        <img src="@/assets/image/mobile/photo-52.jpg">
        <div class="banner-content">
          <span class="banner-content-title">解决方案</span>
          <span>一体化的物流行业解决方案</span>
        </div>
      </div>
      <mobile-solution></mobile-solution>
    </div>
    <div class="footer">
      <mobile-footer></mobile-footer>
    </div>
    <!-- 返回主页 -->
    <div class="mobile-home" @click="back">
      <img src="@/assets/image/mobile/icon-32.png">
    </div>
  </div>
</template>

<script>
  // 头部
  import MobileHeader from '@/components/mobile/mobile-header/mobile-header'
  // 内容
  import MobileSolution from './mobile-solution'
  // 底部
  import MobileFooter from '@/components/mobile/mobile-footer/mobile-footer'

  export default {
    name: 'MobileSolutionMain',
    components: {
      MobileHeader,
      MobileSolution,
      MobileFooter
    },
    methods: {
      back () {
        this.$router.push({
          path: '/mobileHome'
        })
      }
    }
  }
</script>

<style lang="scss">
  @import '@/components/mobile/style/common.scss';
</style>
